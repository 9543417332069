import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Configs" link="https://www.npmjs.com/search?q=%40eliasnorrby" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Reusable configurations for some of my favorite tools &ndash; installed with a single command.
      <br />
      <br />
      <code>prettier commitlint semantic-release dependabot jest git</code>
    </ProjectCard>
    <ProjectCard title=".dotfiles" link="https://github.com/eliasnorrby/dotfiles" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Dotfiles for my workstations. Provisioned with Ansible.
      <br />
      <br />
      <code>vim emacs zsh tmux alacritty hammerspoon karabiner</code>
    </ProjectCard>
    <ProjectCard title="Game of life" link="https://game-of-life.eliasnorrby.com" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
An interactive implementation of Conway's Game of Life.
      <br />
      <br />
      <code>react.js typescript material-ui</code>
    </ProjectCard>
    <ProjectCard title="Verano" link="https://www.veranovokalensemble.se" bg="linear-gradient(to right, #FCCC21 0%, #CC5644 100%)" mdxType="ProjectCard">
Homepage for the choir Verano Vokalensemble, based in Uppsala, Sweden.
      <br />
      <br />
      <code>gatsby.js material-ui netlify-cms</code>
    </ProjectCard>
    <ProjectCard title="Wedding" link="https://wedding-example.eliasnorrby.com" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
A simple website for a friend's wedding.
      <br />
      <br />
      <code>react.js css</code>
    </ProjectCard>
    <ProjectCard title="Notes" link="https://notes.eliasnorrby.com" bg="linear-gradient(to right, #034EFC 0%, #BE03FC 100%)" mdxType="ProjectCard">
Tips, tricks and hacks on various topics.
      <br />
      <br />
      <code>bash vim tmux kubernetes git</code>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      