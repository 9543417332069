import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Send an `}<a href="mailto:elias.norrby@gmail.com" target="_blank" rel="noreferrer noopener">{`email`}</a>{` my way or find me on `}<a href="https://github.com/eliasnorrby/" target="_blank" rel="noreferrer noopener">{`GitHub`}</a>{`, `}<a href="https://www.linkedin.com/in/eliasnorrby/" target="_blank" rel="noreferrer noopener">{`LinkedIn`}</a>{` or `}<a href="https://www.instagram.com/eliasnorrby/" target="_blank" rel="noreferrer noopener">{`Instagram`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      